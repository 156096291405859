<template>
  <div class="contentbody" style="">
    <div class="back"><button @click="go_click">{{t('button.backs')}}</button></div>
    <div style="text-align: center;margin-top:10px">
      <div class="title">
       {{getters.language.value==='cn'?form.data.title_cn:form.data.title_en }}
      </div>
      <div class="divVideo" style="margin-top:28px">
        <video controls autoplay :src="form.data.jump_url"></video>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import { Ajax, MessageTips } from '@/utils'
import {useGetters} from '@/store/use.js'
 import store from "@/store";
export default defineComponent({
  components: {},
  setup() {
    const route = useRouter();
    const getters=useGetters(['language','code'])
    const query = route.currentRoute.value.query;
    const { t, locale } = useI18n()
    const form=reactive({data:{}})
    const getList=(v)=>{
       Ajax.POST({
          url: "/api/video/info",
          data:{id:query.id}
        }).then((res) => {
          if (res.data.code === 10000) {
            form.data=res.data.data;
          }
        });
    }
    const go_click=()=>{
      if(query.to=='index'){
        sessionStorage.setItem('activeIndex',1);
        store.dispatch('app/setactiveIndex', 1);
        route.push('/index');
      }else{
        route.push('/information?ids='+2);
      }
    }
    getList()
    return {
      t,
     route,
     getters,
     form,
     go_click
    };
  },
});
</script>
<style lang="scss" scoped>
.contentbody{
   margin: 40px auto 60px;
  video{width: 100%;}
  .title {
    // height: 100px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    text-align: left;
word-wrap: break-word;
  }
   .back{
     margin-bottom: 27px;
     button{
       width: 68px;
        height: 30px;
        border-radius: 1px;
        border: 1px solid #DDDDDD;
        background: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        cursor: pointer;
     }
   }
}
 @media screen and (min-width: 375px) and (max-width: 750px){
    .contentbody{
      width: 100% !important;
      margin: 20px auto 40px;
      .back{padding: 0 10px;}
      .title{
        font-size: 20px;
        line-height: 28px;
        height: auto;
        padding: 0 10px;
      }
      .divVideo{margin-top: 20px !important;}
      video{width: 100%;height: 230px;}
    }
    
  }
</style>
